import { Center, Container, Image } from '@chakra-ui/react';
import BookingBar from './BookingBar';
import './Landing.css';
import BgVideo from './video1080.mp4';
import About from './About';


function Landing() {
    return (
        <div>
            <Container class="video-wrapper">
                <div dangerouslySetInnerHTML={{
                    __html: `
                        <video
                            loop
                            muted
                            autoplay
                            playsinline
                            src="${BgVideo}"
                            class="bg-vid"
                            poster="/TBR.jpg"
                        />,
                        ` }}>
                </div>
                <div class="header">
                    <Center>
                        <Image src="/Tranquility-Resort-and-spa-logo-upscaled-vertical.png" alt="Tranquility Bay Logo"
                            maxHeight={{ base: 'auto' }}
                            maxWidth={{ base: '30rem', md: '40rem', lg: '50rem' }}
                            mt={{ base: '25vh', md: '20vh' }}
                        />
                    </Center>
                    <Center mt={{ base: '10vh', lg: '15vh' }} display={{ base: 'none', md: 'flex' }}>
                        <BookingBar />
                    </Center>
                </div>
            </Container>
            <About></About>
        </div>
    );
}

export default Landing;