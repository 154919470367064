import {
    Box,
    chakra,
    Container,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden,
    useColorModeValue,
    Link,
    Image,
    useToken,
    useBreakpointValue
} from '@chakra-ui/react'
import { FaFacebook, FaInstagram, FaPinterest } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';

const ListHeader = ({ children }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    )
}

export default function LargeWithNewsletter() {
    const currentYear = new Date().getFullYear();
    const [socialIconColor] = useToken(
        'colors',
        ['teal.500'],
    );
    const align = useBreakpointValue({ base: "center", sm: "center", md: "stretch" });

    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr', md: '2fr 1fr 1.25fr 1.25fr' }}
                    spacing={8} align={align}>
                    <Stack spacing={6} align={align}>
                        <Box>
                            <Link href='/'>
                                <Image src="/logo.png" alt="Tranquility Bay Logo"
                                    maxHeight={{ base: '10' }}
                                    maxWidth={{ base: 'none' }}
                                />
                            </Link>
                        </Box>
                        <Text color={'gray.600'}>© {currentYear} Tranquility Bay Resort</Text>
                        <Stack direction={'row'} spacing={6}>
                            <SocialButton label={'Instagram'} href={'https://www.instagram.com/tranquilitybaybelize/'}>
                                <FaInstagram color={socialIconColor} />
                            </SocialButton>
                            <SocialButton label={'Facebook'} href={'https://www.facebook.com/TranquilityBayResort/'}>
                                <FaFacebook color={socialIconColor} />
                            </SocialButton>
                            <SocialButton label={'Pinterest'} href={'https://www.pinterest.com/tranquilitybayresort/'}>
                                <FaPinterest color={socialIconColor} />
                            </SocialButton>
                            <SocialButton label={'X.com (formerly Twitter)'} href={'https://twitter.com/tranquilitybay'}>
                                <FaSquareXTwitter color={socialIconColor} />
                            </SocialButton>
                        </Stack>
                    </Stack>
                    <Stack>
                        <ListHeader>Experiences</ListHeader>
                        <Box as="a" href={'/experiences/diving'}>
                            Diving
                        </Box>
                        <Box as="a" href={'/experiences/fishing'}>
                            Fishing
                        </Box>
                        <Box as="a" href={'/experiences/snorkeling'}>
                            Snorkeling
                        </Box>
                        <Box as="a" href={'/experiences/excursions'}>
                            Local Excursions
                        </Box>
                    </Stack>
                    <Stack>
                        <ListHeader>Amenities</ListHeader>
                        <Box as="a" href={'/dining'}>
                            The Aquarium Restaurant
                        </Box>
                        <Box as="a" href={'/dining'}>
                            The Tackle Box Sea Bar
                        </Box>
                    </Stack>
                    <Stack>
                        <ListHeader>Accomodations</ListHeader>
                        <Box as="a" href={'/accommodations'}>
                            Cabanas
                        </Box>
                        <Box as="a" target="_blank" href={'https://direct-book.com/properties/tranquilitybayresort&spadirect'}>
                            Book Online
                        </Box>
                        <Box as="a" target="_blank" href={'https://api.whatsapp.com/send?phone=5016705880&text=Hello!%20I%27m%20interested%20in%20booking%20a%20stay%20at%20Tranquility%20Bay%20Resort'}>
                            Book on WhatsApp
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    )
}

const SocialButton = ({
    children,
    label,
    href,
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: 'teal.100',
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};