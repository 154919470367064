import {
    Box,
    Center,
    Container,
    Flex,
    HStack,
    Heading,
    Link,
    Stack,
    Text,
    VStack,
    VisuallyHidden,
    Wrap,
    WrapItem,
    chakra,
    useColorModeValue,
    useToken
} from "@chakra-ui/react";

import { EmailIcon, PhoneIcon, StarIcon } from "@chakra-ui/icons";
import { BsWhatsapp } from "react-icons/bs";
import { FaFacebook, FaInstagram, FaPinterest, FaTwitter } from 'react-icons/fa';

const SocialButton = ({
    children,
    label,
    href,
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('white', 'white')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: 'teal.100',
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

function Contact() {
    const [socialIconColor] = useToken(
        'colors',
        ['teal.500'],
    );

    return (
        <Container maxW={'7xl'} overflowX={'hidden'}>
            <Center>
                <Box
                    bg="teal.500"
                    color="white"
                    borderRadius="lg"
                    m={{ base: 10, md: 16, lg: 10 }}
                    p={{ base: 10, md: 10, lg: 16 }}>
                    <Box p={4}>
                        <Wrap spacing={{ base: 10 }}>
                            <WrapItem>
                                <Box>
                                    <Heading>Contact Us</Heading>
                                    <Text mt={{ base: 5 }} color="white">
                                        Feel free to write us or give us a call!
                                    </Text>
                                    <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                                        <VStack pl={0} spacing={3} alignItems="flex-start">
                                            <Link
                                                p={2}
                                                href={'tel:+501.670.5880'}
                                                fontSize={'md'}
                                                fontWeight={500}
                                                color={"white"}
                                                _hover={{
                                                    textDecoration: 'none',
                                                    color: "white",
                                                }}>
                                                <PhoneIcon mr={3} mt={-1} />
                                                +501 670 5880
                                            </Link>
                                            <Link
                                                p={2}
                                                href={'https://wa.me/+5016705880'}
                                                fontSize={'md'}
                                                fontWeight={500}
                                                color={"white"}
                                                _hover={{
                                                    textDecoration: 'none',
                                                    color: "white",
                                                }}>
                                                <HStack>
                                                    <BsWhatsapp mr={3} mt={-1} />
                                                    <Text pl={1}>
                                                        +501 670 5880
                                                    </Text>
                                                </HStack>
                                            </Link>
                                            <Link
                                                p={2}
                                                href={'mailto:info@tranquilitybaybelize.com'}
                                                fontSize={'md'}
                                                fontWeight={500}
                                                color={"white"}
                                                _hover={{
                                                    textDecoration: 'none',
                                                    color: "white",
                                                }}>
                                                <EmailIcon mr={3} mt={-1} />
                                                info@tranquilitybaybelize.com
                                            </Link>
                                            <Link
                                                p={2}
                                                href={'https://www.google.com/maps/place/Tranquility+Bay+Resort/@18.0925651,-87.8695383,135m/data=!3m1!1e3!4m9!3m8!1s0x8f5b608e7ea01609:0x333404753c012604!5m2!4m1!1i2!8m2!3d18.0924835!4d-87.8694458!16s'}
                                                fontSize={'md'}
                                                fontWeight={500}
                                                color={"white"}
                                                _hover={{
                                                    textDecoration: 'none',
                                                    color: "white",
                                                }}>
                                                <StarIcon mr={3} mt={-1} />
                                                Ambergris Caye, BZ
                                            </Link>
                                        </VStack>
                                    </Box>
                                    <Stack direction={'row'} spacing={6}>
                                        <SocialButton label={'Instagram'} href={'https://www.instagram.com/tranquilitybaybelize/'}>
                                            <FaInstagram color={socialIconColor} />
                                        </SocialButton>
                                        <SocialButton label={'Facebook'} href={'https://www.facebook.com/TranquilityBayResort/'}>
                                            <FaFacebook color={socialIconColor} />
                                        </SocialButton>
                                        <SocialButton label={'Pinterest'} href={'https://www.pinterest.com/tranquilitybayresort/'}>
                                            <FaPinterest color={socialIconColor} />
                                        </SocialButton>
                                        <SocialButton label={'Twitter'} href={'https://twitter.com/tranquilitybay'}>
                                            <FaTwitter color={socialIconColor} />
                                        </SocialButton>
                                    </Stack>
                                </Box>
                            </WrapItem>
                            <WrapItem>
                                <Box bg="white" borderRadius="lg">
                                    <Box m={8} color="#0B0E3F" height={{ base: "770px" }} width={{ base: "80vw", md: "70vw", lg: "29vw" }} overflow="hidden">
                                        <iframe
                                            title="Contact Us Form"
                                            id="JotFormIFrame-232198526665163"
                                            onload="window.parent.scrollTo(0,0)"
                                            src="https://form.jotform.com/232198526665163"
                                            frameborder="0"
                                            style={{ "width": "100%", "height": "100%" }}
                                            scrolling="no"
                                        >
                                        </iframe>
                                    </Box>
                                </Box>
                            </WrapItem>
                        </Wrap>
                    </Box>
                </Box>
            </Center>
            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                pb={{ base: 10, md: 12 }}
                pt={{ base: 10, md: 12 }}
                direction={{ base: 'column', md: 'row' }}>
                <Flex flex={1} justify={{ base: 'center', md: 'center' }} align={'center'} mb={{ base: '6', md: '0' }}>
                    <iframe
                        title="Tranquility Bay Location"
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d485479.32841710735!2d-88.224622586799!3d18.080475560770445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaa6d10549e8e1480!2sTranquility%20Bay!5e0!3m2!1sen!2sbz!4v1571763247367!5m2!1sen!2sbz'
                        width="97%" height="360"
                        style={{ 'border-radius': 'var(--chakra-radii-2xl)' }}
                    /></Flex>
                <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="6">
                    <Heading fontSize={'3xl'} align={'left'}>Plan your escape to Tranquility Bay</Heading>
                    <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                        Your journey to paradise may not be as difficult as you think! You can stay at one of the top Ambergris caye resorts, an area of tranquil and private seclusion, quite easily! There are multiple flights directly from many cities across the US, and once you arrive in Belize City airport, you can hop on a commuter flight to San Pedro without hassle.
                        <br></br><br></br>
                        If you have come from further afield or simply want to rest up, then you can stay at one of the San Pedro Belize resorts for the night, but don’t stay too long, as you’ll be missing out! From San Pedro, we will personally come and meet you, and whisk you away by boat. A pleasant journey on a water taxi will bring you to our shores. Be sure to bring your camera along, as you’ll see some of the most picturesque sights that Central America and the Caribbean has to offer!
                    </Text>
                </Stack>
            </Stack>
        </Container>
    );
}

export default Contact;