import {
    Box,
    Button,
    Center,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image,
    Stack,
    Text
} from '@chakra-ui/react';

import { BiCoffee, BiDrink, BiFoodMenu } from 'react-icons/bi';


function Dining() {
    return (
        <div>
            <Grid templateColumns='repeat(4, 1fr)' pb="8">
                <GridItem w='100%' >
                    <Image src="/restaurant4.jpg" roundedBottomLeft={"2xl"} boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/restaurant3.jpg" boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/restaurant2.jpg" boxShadow={'lg'} />
                </GridItem>
                <GridItem w='100%'>
                    <Image src="/restaurant1.jpg" roundedBottomRight={"2xl"} boxShadow={'lg'} />
                </GridItem>
            </Grid>
            <Container maxW={'7xl'} overflowX={'hidden'}>
                <Heading
                    pb="12"
                    pt="12"
                    fontWeight={600}
                    color="gray.800"
                    outlineColor={"black"}
                    fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                    lineHeight={'110%'}
                    textAlign="center">
                    One of the best known and loved Belize bars and restaurants is at {' '}
                    <Text as={'span'} color={'teal.400'}>
                        Tranquility Bay Resort
                    </Text>
                </Heading>
                <Center pb="8">
                    <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                        Vacations shouldn’t stop at meal time so let us take care of breakfast, lunch, and dinner whilst you enjoy yourself. Our signature restaurant, The Aquarium, prepares delicious food from fresh ingredients and cooked to perfection. We source local ingredients and make use of fresh daily catches for our seafood dishes. We pride ourselves on providing authentic top quality food that catches the flavors and spirit of the Belizean culture. Enjoy a fine-dining experience in a more relaxed intimate setting, with seating looking out over the ocean, perfect for watching the sunset.
                    </Text>
                </Center>
                <Stack
                    align={'center'}
                    spacing={{ base: 8, md: 10 }}
                    pb={{ base: 10, md: 12 }}
                    direction={{ base: 'column', md: 'row' }}>
                    <Flex
                        flex={1}
                        justify={'center'}
                        align={'center'}
                        position={'relative'}
                        w={'full'}>
                        <Box
                            position={'relative'}
                            height={'500px'}
                            rounded={'2xl'}
                            boxShadow={'lg'}
                            width={'full'}
                            overflow={'hidden'}>
                            <Image
                                alt={'Restaurant'}
                                fit={'cover'}
                                align={'center'}
                                w={'100%'}
                                h={'100%'}
                                src={
                                    '/restaurant.jpg'
                                }
                                transform={'translate(0%, 0%)'}
                            />
                        </Box>
                    </Flex>
                    <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="6">
                        <Heading
                            lineHeight={1.1}
                            fontWeight={600}
                            fontSize={{ base: '2xl', sm: '3xl', lg: '5xl' }}>
                            <Text as={'span'} color={'teal.400'}>
                                The Aquarium
                            </Text>
                            <br />
                            <Text
                                as={'span'}
                                position={'relative'}
                                _after={{
                                    content: "''",
                                    width: 'full',
                                    height: '30%',
                                    position: 'absolute',
                                    bottom: 1,
                                    left: 0,
                                    bg: 'teal.300',
                                    zIndex: -1,
                                }}>
                                Restaurant
                            </Text>
                        </Heading>
                        <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                            Experience world‐class meals in one of the best Ambergris Caye restaurants, from traditional Caribbean cuisine to fun family-friendly events such as relaxed barbeques and beach cookouts. Try one of our rum specialities or just kick back in your hammock with a chilled Belikin (the popular local beer of Belize). If you’re looking for ultimate relaxation – you’ve found it. Here at Tranquility Bay Resort, we want to make every aspect of your stay enjoyable and relaxed. This doesn’t change when it comes to the food, using the finest quality ingredients, but offering relaxing al fresco dining options allows you to unwind and enjoy delicious food with the balmy sea breeze keeping you cool.
                        </Text>
                    </Stack>
                </Stack>
                <Stack
                    align={'center'}
                    spacing={{ base: 8, md: 10 }}
                    pb={{ base: 10, md: 12 }}
                    direction={{ base: 'column', md: 'row' }}>
                    <Stack flex={1} spacing={{ base: 5, md: 10 }} mb="6">
                        <Heading
                            lineHeight={1.1}
                            fontWeight={600}
                            fontSize={{ base: '2xl', sm: '3xl', lg: '5xl' }}>
                            <Text as={'span'} color={'teal.400'}>
                                The Tackle Box
                            </Text>
                            <br />
                            <Text
                                as={'span'}
                                position={'relative'}
                                _after={{
                                    content: "''",
                                    width: 'full',
                                    height: '30%',
                                    position: 'absolute',
                                    bottom: 1,
                                    left: 0,
                                    bg: 'teal.300',
                                    zIndex: -1,
                                }}>
                                Sea Bar
                            </Text>
                        </Heading>
                        <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }}>
                            The Tacklebox Sea Bar (a charmingly rustic example of Belize bars) serves the best cocktails on the island. Both are located over the water with a spectacular view of the fish below. Whether you want something crisp and refreshing or sweet and fruity, our extensively trained staff will be able to whip it up for you! Regardless of whether you want alcohol or not, there will be something for everyone. We can even whip up some of the best mocktails that you’ve ever tried, completely alcohol free, but not lacking in flavor! We aim to create a fun and authentic atmosphere at the Tackle Box Sea Bar, so sit back and relax.
                        </Text>
                    </Stack>
                    <Flex
                        flex={1}
                        justify={'center'}
                        align={'center'}
                        position={'relative'}
                        w={'full'}>
                        <Box
                            position={'relative'}
                            height={'400px'}
                            rounded={'2xl'}
                            boxShadow={'lg'}
                            width={'full'}
                            overflow={'hidden'}>
                            <iframe 
                                title='Bar VR Tour'
                                src='https://my.matterport.com/show/?m=u7Q5yQCc5AE&title=0&dh=0'
                                width="100%" height="400px"
                                style={{ 'border-radius': 'var(--chakra-radii-2xl)' }}
                                allow='xr-spatial-tracking'>
                            </iframe>
                        </Box>
                    </Flex>
                </Stack>
                <Grid templateColumns='repeat(auto-fill, minmax(300px, 1fr))' pb="8">
                    <GridItem w='100%' >
                        <Image src="/restaurant5.jpg" />
                    </GridItem>
                    <GridItem w='100%'>
                        <Image src="/restaurant6.jpg" />
                    </GridItem>
                    <GridItem w='100%'>
                        <Image src="/lobster2.jpg" />
                    </GridItem>
                    <GridItem w='100%'>
                        <Image src="/restaurant7.jpg" />
                    </GridItem>
                </Grid>
                <Heading fontSize={'3xl'} align={'center'} mb="6">Our Menu</Heading>
                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }} pb="6">
                    Enjoy bountiful breakfasts, lunches in the restaurant or on‐the‐go, and gourmet dinners and desserts. Our amazing chef and their staff take excellent care to make every dining experience something to remember. Try a different special every night or bring in your own catch of the day and we’ll cook it up just the way you like it.
                </Text>
                <Grid templateColumns='repeat(auto-fill, minmax(250px, 1fr))' pb="8" align="center" gap={6}>
                    <GridItem w='100%' >
                        <Button onClick={() => window.open("/TBRBreakfast.pdf", '_blank')} bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                            color={'white'}
                            colorScheme="teal"
                            _hover={{
                                bg: 'teal.400',
                            }}
                            leftIcon={<BiCoffee />}
                            size="lg">
                            Breakfast Menu
                        </Button>
                    </GridItem>
                    <GridItem w='100%'>
                        <Button onClick={() => window.open("/TBRLunch.pdf", '_blank')} bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                            color={'white'}
                            colorScheme="teal"
                            _hover={{
                                bg: 'teal.400',
                            }}
                            leftIcon={<BiFoodMenu />} size="lg">
                            Lunch Menu
                        </Button>
                    </GridItem>
                    <GridItem w='100%'>
                        <Button onClick={() => window.open("/TBRDinner.pdf", '_blank')} bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                            color={'white'}
                            colorScheme="teal"
                            _hover={{
                                bg: 'teal.400',
                            }}
                            leftIcon={<BiFoodMenu />} size="lg">
                            Dinner Menu
                        </Button>
                    </GridItem>
                    <GridItem w='100%'>
                        <Button onClick={() => window.open("/TBRDrinks.pdf", '_blank')} bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                            color={'white'}
                            colorScheme="teal"
                            _hover={{
                                bg: 'teal.400',
                            }}
                            leftIcon={<BiDrink />} size="lg">
                            Drink Menu
                        </Button>
                    </GridItem>
                </Grid>

                <Text color={'gray.600'} fontSize={{ base: "md", md: "lg" }} pb="6">
                    Planning an excursion or fishing trip or any other full day excursion? Let us know we can do the service and can pack lunches for you to take along if traveling with picky eaters and would like something specific? No problem, let us know your preferences ahead of time and we’ll make sure something delicious is available at every meal as well to fit your appetite, let us know if you are allergic to any type of food or have a specific diet ahead of time so we can be prepared to satisfy your needs.
                </Text>
            </Container>
        </div>
    );
}

export default Dining;